<template>
	<div class="convention-hall page">
		<van-nav-bar class="nav-bar" title="金标演示站" />
		<div class="convention-item">
			<van-swipe :autoplay="3000" lazy-render class="my-swipe" :show-indicators="false">
				<van-swipe-item v-for="image in images" :key="image"><img :src="image" class="banner_img" /></van-swipe-item>
			</van-swipe>
			<div class="describe">
				<ul>
					<li>
						<div>金标演示站</div>
						<div>华人第一福利品牌</div>
					</li>
					<li>
						为了会员权益，网站只展示
						<b style="font-size: 16px; color: #f8c693">1%</b>
						资源并且不定期更新只为展示实力，如需查询预约更多资源请联系上级接待
					</li>
				</ul>
				<div>本平台所有资源真实丶安全丶可靠，全国空降丶同城约炮选取你心仪的她，与您零距离邂逅，快加入我们吧</div>
			</div>

			<van-tabs animated sticky line-width="100px" :swipeable="true">
				<van-tab title="∏资源">
					<!-- <div class="card">本平台全网最真实外围+商务伴游+同城激情，为保证每个用户的个人隐私，客户仅限通过联系接待员或平台资深会员实名推荐才可加入。</div> -->
					<!-- <div class="address">
						<van-row type="flex" justify="" gutter="65" v-for="(val, key) in addlist" :key="key">
							<van-col span="" offset="" @click="addgo(v)" v-for="(v, k) in val" :key="k">{{ v.name }}</van-col>
						</van-row>
					</div> -->
					<div class="xuanfei" v-for="(val, key) in xuanfeilist" :key="key" @click="xfts(val.id)">
						<div class="left-div">
							<div>ID:{{ val.itemid }}</div>
							<div>
								<ul>
									<li>昵称: {{ val.nickname }}</li>
									<li>类型：{{ val.typelabel }}</li>
									<li>所在地区：{{ val.region }}</li>
									<li>服务项目: 激活可见</li>
								</ul>
							</div>
							<div>
								<ul>
									<li>
										<img
											src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAYAAAA6/NlyAAAAAXNSR0IArs4c6QAABIRJREFUaEPtWl1oHFUU/s7Miq8aERFFihbRh7agVuhDsS2CL75opWClah+Umuw9s6EiRgsdENQHze7cu6tEReNPIqJGwRfR+gMqilIfbEQRxArqm4l9a6AzR27ZDZNkN/Ozm92S2QvzsnO+853vnjvn/i2hYI0KphdDwZs948MMDzO8yXpgOKQ3WULXyBlmeJjhnD1QrVavdRxnGxFtA2CfawBcEnus5/9izx8ATorIz67r/lIul//MSb0urKdDWmt9MREdFJGDAG7vMuATRDQrIrPMvNSlr2V4TwTX6/XLRKQMwIq9vlfBWT9E9FsURW+7rmvK5fK/3fruSrDv+6WRkREr1D7XdRtMAv53APWFhYW67/vn8nLlFhwEwc1E9BKAm/KS58T9CGCCmT/Jg88l2BizT0Q+y0PYK4yIPOR53itZ/WUWbIxRIqKzEm2Q/ZPM/HQW35kEB0HgE9HxLAQbbSsi93ue92ZantSCjTGPi8gzaR2vY2enmHkAlzfn5q5dEtEBpdS7aRylEmyMsdPNTBqHHWxeBfCViJzyPO9ky6ZWq13hOM4OEdlORI81OyEXTVrRiYKr1eoW13W/ALAlVyTAfmaeS8I2Go2tYRi+DGBPkm2790T0t13sKKV+XQ+fKFhr/RqAB3ME8Z0NPusqqZuiSEQfKKXuzi1Ya22FWsFZ2zlmvmg1qDl37xGRqxzHORtF0fzi4uKc7/tn47Za60cAvJCV1NqLyBOe53WsNR0zrLW+2n53OYfyEWaeagVsfdmpjIjuaiPiDIBjzFyPvwuC4D0i2p9HtOu6t46Njf3Qduh3cqi1fg7A0RyEU8x8pIWbnJzcWiqVPk5aehLRs0qpiRau0WjcGIbhNwAuzRoDEb2ulGr7GbbNcK1W2+k4zrcA3KxkpVJpx+jo6E+x7H4J4LY0fqIoOlSpVN5q2RpjZpo7rzTwFTYiss/zPFtsV7S2goMgmLHbvMwswBlmtnve8y0IggNE9E4GP/PMbPfO55vW+hiApzLgl01F5H3P8+5JFKy1tvvYT/OQAPiamXfHAn4DwKEsvohou1LqlMXU6/U7oyj6KAt+le0dqzcZazIcBMHDRLRccDKSvcjMozHBtnDcksWHLWxKqQ8tZmpq6sqlpaV/suDjtkTESimz4rfVzgonuHBDulls8s6BF1LRmvU8777EotUUvJeIPs/z7Vwg01IYRdGuSqWyZvHRcaVljJkWkQdyiB74wgPA88z8aLvYOwpuNBo7wzD8PodgCxnk0vI0gN3M/Fcmwc2hPUFEmY5QmiQD2zwAOMzM050Slbg9NMbMiUi7RX9S8vu+PQQwzcyH1wssjeAbAJywW7okhR3e9+UAAMDpMAz3jo+P2yHdsSUKtshCHfG0uqpQh3gx0cdFxM85tDcEtmHHtDHRxTmIj4kuzlVLS3ShLtNaogt1XRqvQPZCPAxD5TjOvZv6Qnx12S3MXx7azTeF+FPLhky0PXaaamnZY86BuhsKHmj394F8mOE+dPJAKYYZHmj394F8mOE+dPJAKQqX4f8BYPRTW5RGL5kAAAAASUVORK5CYII="
											alt=""
										/>
										<span>{{ val.views }}</span>
									</li>
									<li>
										<img
											src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAYAAAA6/NlyAAAAAXNSR0IArs4c6QAABOBJREFUaEPtmmtoHFUUx///2SiKQdsKRqUoBfWD+kGREltRqlZBK6LQRLRaK4So+ZLt3DtJfEEQIjRzZ1O1vioSXwS0KC3EkrZY+sUg2hoQRNT6QFFo0agItWqyR2aSLNvsc3ZnNskm9+PuOed/fveeO3PPzBCLbHCR8WIJuN5XfGmFl1a4zmag4pIW110HYB3IJlCapubFOgCRw9T606jmSXp7T8c5Z90C4VoImyByPogTAL+AJd9BrC9p24fL1QsFLMZcAwvtENwF4LwiIkcg2Emtd5abyGw7GRw8A+PjfYBsAbCieBwOQeRNar2vlF7ZwJIyr0DQXirgrP+PAOleqq7hMH5B9VjsA7A2jB+A16n0Q8V8ygIWz7wLoCWkeJY5+6jUk+X4i+d2AwFsohz7PDY/UOlVhXxLAlcPOy1NvA9Y99O2/y6UjKTMOxC0Vgia7XaUSl+aL05R4MhgM8qyF0w8QNsez9mznhkE4O/XqMYuKp0zeQWBxXNfBvhwVOqZOMRBwNpM2/555jcx5gUQHZFrCZLU+tnsuHmBJZW6ApIeA3Ba5ElMBRwFrQdp20fFc12AOiad75GWa+k4x2fi5wf2XA+gHVMSM2HHABkG+FS8OuygUi8VBBbXXQWLnwFYFm8itYou71E5GwsDe+7T8c96rWADnd+pdObgklPS4plPAKyuaUpxi1mJ5dy69Q9fJh/wsRLHxrjTiz5+Q/oydnZ9kwMsO3aci39O/hq94lxH5HVUajQXeKD/KqQt/3ZUX0NwN7XenQvseXcCsqe+aH0aaadyXs0FNqYTxPa6AyaeoK2fyQVOuQ6E/fUHLPfQdvyO79SrtHj9WwDLP8TX10g0XMxk8sd8e/h2QD6oK1rBt9T6krwnLUmlVkDSv9UVMDhEpTblBQ6uZ54ZAnBv3UDPahFzT1rGbALxdt0Ac7KZdrd/XA5G/vbQmI9BNNcB9AEqfWs2R4F+uH8zYL2x8IHTHVRdmV644ApP7+WDAG5cwNDjSDRczmTSb4Yyo/AzrQGzEWnsWrjA8hqV0zY7/+JPLVNmGIINCxOaG6jU3nDArrsGFkcAnL2woLmNSvXky7n0g3hj2kAEncaCGMQhrLxoPVtbJysCDi5gxmwH0TnvgUVOgtbNM81+5cAtLQk0N4+AWD+/oUVROaliOZYs6Rlncd0r4b8fIvO+s5n7ieBzVKpkFZYNHJS2D21xP4AL5h7wlJvrIdq6rDNDKOAA+vltF+LfxFcAGucN9H8Ty9jT82c5+YQGDqBTqTMh6RPlCMRuQ6uZtp1pDkrpVQSc2dfG/QnkylIisf1Pa3WY7zv8PKoCnlptMwrBmtig8gc+jkTDDUwm/a0ValQNHEBX/UlEqJzHMDF5B7u7fwnlNW0cCXANoYcxMXkfu7v/qgQ2kpLOFhbPfRHgo5UmU9xPDJXjVBs7shXOXMgGTBsmpR/k8mqTm/Y/BoFDrd+KIl7kwEF5+x+wAf0gbqoqSXI/rLTDpPN5VXGynGMBDqB7ey00NvrQqrJkoynh2dqxAWdK3JjbQD4GyPXlgcsIYBkq9WF59uGsYgfOgHueD/14kSPp1wB90Fh775oBT926vKtBeSTrm03/XvoRKPuQ5h5qHfvL+JoChyu+eKyXgOOZ1/kTdWmF589axJPJolvh/wF8iXZMtgf/PgAAAABJRU5ErkJggg=="
											alt=""
										/>
										<span>{{ val.collection }}</span>
									</li>
								</ul>
							</div>
						</div>
						<div class="right-div"><img :src="domain + val.flagimg" alt="" /></div>
						<i class="sign-i">已认证</i>
					</div>
				</van-tab>
				<van-tab title="实时配对">
					<div class="scroll-cmp">
						<van-swipe :height="52" vertical :autoplay="2000" :duration="4000" :show-indicators="false">
							<van-swipe-item v-for="(val, key) in peiduilist" :key="key">
								<div>
									<span>{{ val.name }}</span>
									<span>{{ val.tel }}</span>
								</div>
								<div>
									<span>{{ val.title }}</span>
								</div>
							</van-swipe-item>
						</van-swipe>
					</div>
				</van-tab>
				<van-tab title="约炮心得">
					<div class="scroll-cmp">
						<van-swipe :height="52" vertical :autoplay="2000" :duration="4000" :show-indicators="false">
							<van-swipe-item v-for="(val, key) in yuepaolist" :key="key">
								<div>
									<span>{{ val.name }}</span>
									<span>{{ val.tel }}</span>
								</div>
								<div>
									<span>{{ val.title }}</span>
								</div>
							</van-swipe-item>
						</van-swipe>
					</div>
				</van-tab>
				<van-tab title="服务条款">
					<div class="card">本平台全网最真实外围+商务伴游+同城激情，为保证每个用户的个人隐私，客户仅限通过联系接待员或平台资深会员实名推荐才可加入。</div>
					<div class="rig-box">
						<p class="rig-title">有哪些资源?</p>
						<p class="rig-content">网红、模特、空姐、嫩模、大学生，只有您想不到，没有本平台办不到</p>
						<p class="rig-title">服务范围?</p>
						<p class="rig-content">同城免费约炮，任意地点全国空降，国内一二线城市当地均有，三线城市也可通过联系接待员预约安排。</p>
						<p class="rig-content">本平台全网最真实外围+商务伴游+同城激情，为保证每个用户的个人隐私，客户仅限通过联系接待员或平台资深会员实名推荐才可加入。</p>
					</div>
				</van-tab>
			</van-tabs>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			domain: this.serviceUrl,
			addlist: [
				{
					0: '北京',
					1: '上海',
					2: '广州',
					3: '深圳'
				},
				{
					0: '南京',
					1: '长沙',
					2: '重庆',
					3: '西安'
				}
			],
			images: ['img/home/1.png', 'img/home/2.png', 'img/home/3.png', 'img/home/4.png'],
			xuanfeilist: [],
			peiduilist: [
				{
					name: '步先生',
					tel: '(134****6487)',
					title: '通过本站已预约......'
				},				{
					name: '张先生',
					tel: '(133****6489)',
					title: '正在服务中......'
				},				{
					name: '郑先生',
					tel: '(181****6490)',
					title: '正在服务中......'
				},				{
					name: '张先生',
					tel: '(195****6491)',
					title: '正在服务中......'
				},				{
					name: '赵先生',
					tel: '(190****6492)',
					title: '通过本站已预约......'
				},				{
					name: '李先生',
					tel: '(199****6493)',
					title: '正在服务中......'
				},				{
					name: '郑先生',
					tel: '(168****6494)',
					title: '正在服务中......'
				},				{
					name: '周先生',
					tel: '(189****6496)',
					title: '通过本站已预约......'
				},				{
					name: '王先生',
					tel: '(177****6497)',
					title: '正在服务中......'
				},				{
					name: '郑先生',
					tel: '(188****6498)',
					title: '正在服务中......'
				},				{
					name: '李先生',
					tel: '(173****6499)',
					title: '正在服务中......'
				},				{
					name: '楚先生',
					tel: '(198****6500)',
					title: '正在服务中......'
				},				{
					name: '赵先生',
					tel: '(177****6501)',
					title: '通过本站已预约......'
				},				{
					name: '卫先生',
					tel: '(161****6502)',
					title: '通过本站已预约......'
				},				{
					name: '陈先生',
					tel: '(183****6503)',
					title: '通过本站已预约......'
				},				{
					name: '张先生',
					tel: '(131****6504)',
					title: '通过本站已预约......'
				},				{
					name: '张先生',
					tel: '(180****6506)',
					title: '通过本站已预约......'
				},				{
					name: '卫先生',
					tel: '(170****6507)',
					title: '正在服务中......'
				},				{
					name: '冯先生',
					tel: '(132****6508)',
					title: '通过本站已预约......'
				},				{
					name: '赵先生',
					tel: '(173****6509)',
					title: '正在服务中......'
				},				{
					name: '王先生',
					tel: '(159****6510)',
					title: '通过本站已预约......'
				},				{
					name: '卫先生',
					tel: '(141****6511)',
					title: '通过本站已预约......'
				},				{
					name: '张先生',
					tel: '(185****6513)',
					title: '正在服务中......'
				},				{
					name: '步先生',
					tel: '(135****6514)',
					title: '正在服务中......'
				},				{
					name: '李先生',
					tel: '(176****6515)',
					title: '通过本站已预约......'
				},				{
					name: '郑先生',
					tel: '(168****6516)',
					title: '通过本站已预约......'
				},				{
					name: '王先生',
					tel: '(177****6517)',
					title: '通过本站已预约......'
				},				{
					name: '王先生',
					tel: '(191****6518)',
					title: '通过本站已预约......'
				},				{
					name: '李先生',
					tel: '(149****6519)',
					title: '通过本站已预约......'
				},				{
					name: '钱先生',
					tel: '(180****6521)',
					title: '通过本站已预约......'
				},				{
					name: '陈先生',
					tel: '(130****6523)',
					title: '通过本站已预约......'
				},				{
					name: '孙先生',
					tel: '(192****6525)',
					title: '正在服务中......'
				},				{
					name: '周先生',
					tel: '(166****6526)',
					title: '通过本站已预约......'
				},				{
					name: '步先生',
					tel: '(176****6528)',
					title: '通过本站已预约......'
				},				{
					name: '王先生',
					tel: '(172****6529)',
					title: '正在服务中......'
				},				{
					name: '赵先生',
					tel: '(131****6531)',
					title: '通过本站已预约......'
				},				{
					name: '孙先生',
					tel: '(151****6533)',
					title: '通过本站已预约......'
				},				{
					name: '孙先生',
					tel: '(151****6535)',
					title: '正在服务中......'
				},				{
					name: '王先生',
					tel: '(164****6536)',
					title: '通过本站已预约......'
				},				{
					name: '钱先生',
					tel: '(147****6538)',
					title: '通过本站已预约......'
				},				{
					name: '楚先生',
					tel: '(170****6540)',
					title: '正在服务中......'
				},				{
					name: '周先生',
					tel: '(181****6541)',
					title: '通过本站已预约......'
				},				{
					name: '王先生',
					tel: '(153****6543)',
					title: '通过本站已预约......'
				},				{
					name: '陈先生',
					tel: '(181****6545)',
					title: '通过本站已预约......'
				},				{
					name: '赵先生',
					tel: '(143****6546)',
					title: '通过本站已预约......'
				},				{
					name: '钱先生',
					tel: '(143****6548)',
					title: '正在服务中......'
				},				{
					name: '郑先生',
					tel: '(159****6550)',
					title: '通过本站已预约......'
				},				{
					name: '楚先生',
					tel: '(133****6551)',
					title: '通过本站已预约......'
				},				{
					name: '钱先生',
					tel: '(130****6552)',
					title: '正在服务中......'
				},				{
					name: '卫先生',
					tel: '(151****6554)',
					title: '正在服务中......'
				},				{
					name: '陈先生',
					tel: '(136****6555)',
					title: '正在服务中......'
				},				{
					name: '张先生',
					tel: '(186****6556)',
					title: '正在服务中......'
				},				{
					name: '孙先生',
					tel: '(192****6558)',
					title: '通过本站已预约......'
				},				{
					name: '吴先生',
					tel: '(171****6559)',
					title: '正在服务中......'
				},				{
					name: '赵先生',
					tel: '(134****6560)',
					title: '正在服务中......'
				},				{
					name: '钱先生',
					tel: '(199****6561)',
					title: '通过本站已预约......'
				},				{
					name: '周先生',
					tel: '(133****6562)',
					title: '通过本站已预约......'
				},				{
					name: '孙先生',
					tel: '(177****6563)',
					title: '正在服务中......'
				},				{
					name: '吕先生',
					tel: '(149****6564)',
					title: '正在服务中......'
				},				{
					name: '吴先生',
					tel: '(190****6565)',
					title: '正在服务中......'
				},				{
					name: '吴先生',
					tel: '(169****6566)',
					title: '正在服务中......'
				},				{
					name: '吴先生',
					tel: '(130****6567)',
					title: '通过本站已预约......'
				},				{
					name: '陈先生',
					tel: '(148****6568)',
					title: '通过本站已预约......'
				},				{
					name: '孙先生',
					tel: '(170****6570)',
					title: '正在服务中......'
				},				{
					name: '李先生',
					tel: '(133****6571)',
					title: '正在服务中......'
				},				{
					name: '王先生',
					tel: '(153****6572)',
					title: '正在服务中......'
				},				{
					name: '王先生',
					tel: '(160****6573)',
					title: '正在服务中......'
				},				{
					name: '赵先生',
					tel: '(132****6574)',
					title: '通过本站已预约......'
				},				{
					name: '步先生',
					tel: '(141****6575)',
					title: '正在服务中......'
				},				{
					name: '陈先生',
					tel: '(162****6576)',
					title: '正在服务中......'
				},				{
					name: '李先生',
					tel: '(174****6578)',
					title: '通过本站已预约......'
				},				{
					name: '卫先生',
					tel: '(195****6579)',
					title: '正在服务中......'
				},				{
					name: '郑先生',
					tel: '(165****6581)',
					title: '通过本站已预约......'
				},				{
					name: '张先生',
					tel: '(166****6582)',
					title: '正在服务中......'
				},				{
					name: '吴先生',
					tel: '(156****6583)',
					title: '正在服务中......'
				},				{
					name: '张先生',
					tel: '(150****6584)',
					title: '通过本站已预约......'
				},				{
					name: '孙先生',
					tel: '(152****6585)',
					title: '通过本站已预约......'
				},				{
					name: '吕先生',
					tel: '(195****6586)',
					title: '通过本站已预约......'
				},				{
					name: '李先生',
					tel: '(141****6587)',
					title: '通过本站已预约......'
				},				{
					name: '吕先生',
					tel: '(195****6588)',
					title: '通过本站已预约......'
				},				{
					name: '赵先生',
					tel: '(178****6589)',
					title: '通过本站已预约......'
				},				{
					name: '孙先生',
					tel: '(155****6591)',
					title: '通过本站已预约......'
				},				{
					name: '孙先生',
					tel: '(154****6592)',
					title: '通过本站已预约......'
				},				{
					name: '郑先生',
					tel: '(186****6593)',
					title: '正在服务中......'
				},				{
					name: '卫先生',
					tel: '(190****6594)',
					title: '正在服务中......'
				},				{
					name: '陈先生',
					tel: '(143****6595)',
					title: '通过本站已预约......'
				},				{
					name: '王先生',
					tel: '(155****6596)',
					title: '正在服务中......'
				},				{
					name: '李先生',
					tel: '(174****6597)',
					title: '正在服务中......'
				},				{
					name: '赵先生',
					tel: '(161****6598)',
					title: '通过本站已预约......'
				},				{
					name: '冯先生',
					tel: '(179****6599)',
					title: '正在服务中......'
				},				{
					name: '钱先生',
					tel: '(188****6600)',
					title: '正在服务中......'
				},				{
					name: '赵先生',
					tel: '(162****6602)',
					title: '通过本站已预约......'
				},				{
					name: '王先生',
					tel: '(197****6603)',
					title: '通过本站已预约......'
				},				{
					name: '吴先生',
					tel: '(179****6604)',
					title: '通过本站已预约......'
				},				{
					name: '钱先生',
					tel: '(198****6605)',
					title: '正在服务中......'
				},				{
					name: '楚先生',
					tel: '(181****6606)',
					title: '通过本站已预约......'
				},				{
					name: '楚先生',
					tel: '(147****6607)',
					title: '通过本站已预约......'
				},				{
					name: '吕先生',
					tel: '(152****6608)',
					title: '通过本站已预约......'
				},				{
					name: '吴先生',
					tel: '(159****6609)',
					title: '通过本站已预约......'
				},				{
					name: '张先生',
					tel: '(158****6610)',
					title: '通过本站已预约......'
				}
			],
			yuepaolist: [
				{
					name: '赵先生',
					tel: '(155****0523)',
					title: '床上满分 可一字马 性格超好 氵超多' 
				},				{
					name: '王先生',
					tel: '(172****0524)',
					title: '朋友介绍的，妞可以年轻漂亮，服务够莞式。' 
				},				{
					name: '孙先生',
					tel: '(190****0526)',
					title: '联系约好时间叫我去她家操她，皮肤还是很紧致' 
				},				{
					name: '吕先生',
					tel: '(157****0527)',
					title: '联系约好时间叫我去她家操她，皮肤还是很紧致' 
				},				{
					name: '周先生',
					tel: '(197****0528)',
					title: '床上满分 可一字马 性格超好 氵超多' 
				},				{
					name: '吕先生',
					tel: '(172****0529)',
					title: '联系约好时间叫我去她家操她，皮肤还是很紧致' 
				},				{
					name: '李先生',
					tel: '(172****0531)',
					title: '颜值很高，服务很专业，还会热舞...' 
				},				{
					name: '孙先生',
					tel: '(177****0532)',
					title: '朋友介绍的，妞可以年轻漂亮，服务够莞式。' 
				},				{
					name: '李先生',
					tel: '(133****0533)',
					title: '联系约好时间叫我去她家操她，皮肤还是很紧致' 
				},				{
					name: '步先生',
					tel: '(197****0534)',
					title: '联系约好时间叫我去她家操她，皮肤还是很紧致' 
				},				{
					name: '步先生',
					tel: '(169****0535)',
					title: '朋友介绍的，妞可以年轻漂亮，服务够莞式。' 
				},				{
					name: '王先生',
					tel: '(136****0536)',
					title: '朋友介绍的，妞可以年轻漂亮，服务够莞式。' 
				},				{
					name: '周先生',
					tel: '(160****0538)',
					title: '床上满分 可一字马 性格超好 氵超多' 
				},				{
					name: '王先生',
					tel: '(161****0539)',
					title: '颜值很高，服务很专业，还会热舞...' 
				},				{
					name: '周先生',
					tel: '(166****0540)',
					title: '联系约好时间叫我去她家操她，皮肤还是很紧致' 
				},				{
					name: '吕先生',
					tel: '(153****0541)',
					title: '朋友介绍的，妞可以年轻漂亮，服务够莞式。' 
				},				{
					name: '卫先生',
					tel: '(169****0542)',
					title: '联系约好时间叫我去她家操她，皮肤还是很紧致' 
				},				{
					name: '吕先生',
					tel: '(141****0543)',
					title: '颜值很高，服务很专业，还会热舞...' 
				},				{
					name: '冯先生',
					tel: '(151****0545)',
					title: '联系约好时间叫我去她家操她，皮肤还是很紧致' 
				},				{
					name: '楚先生',
					tel: '(189****0546)',
					title: '床上满分 可一字马 性格超好 氵超多' 
				},				{
					name: '周先生',
					tel: '(182****0547)',
					title: '床上满分 可一字马 性格超好 氵超多' 
				},				{
					name: '冯先生',
					tel: '(177****0548)',
					title: '联系约好时间叫我去她家操她，皮肤还是很紧致' 
				},				{
					name: '卫先生',
					tel: '(188****0549)',
					title: '朋友介绍的，妞可以年轻漂亮，服务够莞式。' 
				},				{
					name: '李先生',
					tel: '(138****0551)',
					title: '联系约好时间叫我去她家操她，皮肤还是很紧致' 
				},				{
					name: '孙先生',
					tel: '(196****0552)',
					title: '床上满分 可一字马 性格超好 氵超多' 
				},				{
					name: '吴先生',
					tel: '(132****0553)',
					title: '朋友介绍的，妞可以年轻漂亮，服务够莞式。' 
				},				{
					name: '步先生',
					tel: '(195****0554)',
					title: '床上满分 可一字马 性格超好 氵超多' 
				},				{
					name: '楚先生',
					tel: '(148****0556)',
					title: '颜值很高，服务很专业，还会热舞...' 
				},				{
					name: '李先生',
					tel: '(194****0557)',
					title: '朋友介绍的，妞可以年轻漂亮，服务够莞式。' 
				},				{
					name: '吴先生',
					tel: '(145****0558)',
					title: '床上满分 可一字马 性格超好 氵超多' 
				},				{
					name: '步先生',
					tel: '(131****0560)',
					title: '联系约好时间叫我去她家操她，皮肤还是很紧致' 
				},				{
					name: '吴先生',
					tel: '(137****0561)',
					title: '颜值很高，服务很专业，还会热舞...' 
				},				{
					name: '赵先生',
					tel: '(189****0562)',
					title: '颜值很高，服务很专业，还会热舞...' 
				},				{
					name: '王先生',
					tel: '(199****0563)',
					title: '联系约好时间叫我去她家操她，皮肤还是很紧致' 
				},				{
					name: '步先生',
					tel: '(164****0564)',
					title: '床上满分 可一字马 性格超好 氵超多' 
				},				{
					name: '赵先生',
					tel: '(177****0566)',
					title: '朋友介绍的，妞可以年轻漂亮，服务够莞式。' 
				},				{
					name: '楚先生',
					tel: '(177****0567)',
					title: '床上满分 可一字马 性格超好 氵超多' 
				},				{
					name: '楚先生',
					tel: '(173****0568)',
					title: '床上满分 可一字马 性格超好 氵超多' 
				},				{
					name: '孙先生',
					tel: '(169****0569)',
					title: '颜值很高，服务很专业，还会热舞...' 
				},				{
					name: '步先生',
					tel: '(173****0570)',
					title: '床上满分 可一字马 性格超好 氵超多' 
				},				{
					name: '孙先生',
					tel: '(133****0571)',
					title: '床上满分 可一字马 性格超好 氵超多' 
				},				{
					name: '冯先生',
					tel: '(191****0573)',
					title: '联系约好时间叫我去她家操她，皮肤还是很紧致' 
				},				{
					name: '周先生',
					tel: '(164****0574)',
					title: '颜值很高，服务很专业，还会热舞...' 
				},				{
					name: '陈先生',
					tel: '(139****0575)',
					title: '朋友介绍的，妞可以年轻漂亮，服务够莞式。' 
				},				{
					name: '钱先生',
					tel: '(135****0576)',
					title: '颜值很高，服务很专业，还会热舞...' 
				},				{
					name: '张先生',
					tel: '(164****0577)',
					title: '颜值很高，服务很专业，还会热舞...' 
				},				{
					name: '郑先生',
					tel: '(174****0578)',
					title: '朋友介绍的，妞可以年轻漂亮，服务够莞式。' 
				},				{
					name: '张先生',
					tel: '(186****0579)',
					title: '床上满分 可一字马 性格超好 氵超多' 
				},				{
					name: '赵先生',
					tel: '(189****0581)',
					title: '颜值很高，服务很专业，还会热舞...' 
				},				{
					name: '吕先生',
					tel: '(182****0582)',
					title: '朋友介绍的，妞可以年轻漂亮，服务够莞式。' 
				},				{
					name: '周先生',
					tel: '(135****0583)',
					title: '联系约好时间叫我去她家操她，皮肤还是很紧致' 
				},				{
					name: '孙先生',
					tel: '(180****0584)',
					title: '床上满分 可一字马 性格超好 氵超多' 
				},				{
					name: '吴先生',
					tel: '(138****0585)',
					title: '朋友介绍的，妞可以年轻漂亮，服务够莞式。' 
				},				{
					name: '张先生',
					tel: '(160****0587)',
					title: '颜值很高，服务很专业，还会热舞...' 
				},				{
					name: '张先生',
					tel: '(184****0588)',
					title: '联系约好时间叫我去她家操她，皮肤还是很紧致' 
				},				{
					name: '王先生',
					tel: '(132****0589)',
					title: '朋友介绍的，妞可以年轻漂亮，服务够莞式。' 
				},				{
					name: '吴先生',
					tel: '(189****0590)',
					title: '床上满分 可一字马 性格超好 氵超多' 
				},				{
					name: '卫先生',
					tel: '(142****0591)',
					title: '颜值很高，服务很专业，还会热舞...' 
				},				{
					name: '赵先生',
					tel: '(180****0592)',
					title: '联系约好时间叫我去她家操她，皮肤还是很紧致' 
				},				{
					name: '步先生',
					tel: '(177****0593)',
					title: '朋友介绍的，妞可以年轻漂亮，服务够莞式。' 
				},				{
					name: '楚先生',
					tel: '(195****0595)',
					title: '联系约好时间叫我去她家操她，皮肤还是很紧致' 
				},				{
					name: '陈先生',
					tel: '(190****0596)',
					title: '床上满分 可一字马 性格超好 氵超多' 
				},				{
					name: '冯先生',
					tel: '(180****0597)',
					title: '朋友介绍的，妞可以年轻漂亮，服务够莞式。' 
				},				{
					name: '郑先生',
					tel: '(179****0598)',
					title: '床上满分 可一字马 性格超好 氵超多' 
				},				{
					name: '陈先生',
					tel: '(198****0599)',
					title: '朋友介绍的，妞可以年轻漂亮，服务够莞式。' 
				},				{
					name: '李先生',
					tel: '(180****0601)',
					title: '颜值很高，服务很专业，还会热舞...' 
				},				{
					name: '王先生',
					tel: '(163****0602)',
					title: '颜值很高，服务很专业，还会热舞...' 
				},				{
					name: '孙先生',
					tel: '(136****0603)',
					title: '联系约好时间叫我去她家操她，皮肤还是很紧致' 
				},				{
					name: '周先生',
					tel: '(195****0604)',
					title: '床上满分 可一字马 性格超好 氵超多' 
				},				{
					name: '李先生',
					tel: '(159****0605)',
					title: '联系约好时间叫我去她家操她，皮肤还是很紧致' 
				},				{
					name: '钱先生',
					tel: '(187****0606)',
					title: '颜值很高，服务很专业，还会热舞...' 
				},				{
					name: '吴先生',
					tel: '(165****0608)',
					title: '颜值很高，服务很专业，还会热舞...' 
				},				{
					name: '吴先生',
					tel: '(196****0610)',
					title: '床上满分 可一字马 性格超好 氵超多' 
				},				{
					name: '陈先生',
					tel: '(194****0611)',
					title: '联系约好时间叫我去她家操她，皮肤还是很紧致' 
				},				{
					name: '吴先生',
					tel: '(183****0612)',
					title: '联系约好时间叫我去她家操她，皮肤还是很紧致' 
				},				{
					name: '张先生',
					tel: '(190****0614)',
					title: '朋友介绍的，妞可以年轻漂亮，服务够莞式。' 
				},				{
					name: '陈先生',
					tel: '(138****0615)',
					title: '颜值很高，服务很专业，还会热舞...' 
				},				{
					name: '赵先生',
					tel: '(188****0616)',
					title: '床上满分 可一字马 性格超好 氵超多' 
				},				{
					name: '王先生',
					tel: '(135****0617)',
					title: '颜值很高，服务很专业，还会热舞...' 
				},				{
					name: '张先生',
					tel: '(141****0618)',
					title: '联系约好时间叫我去她家操她，皮肤还是很紧致' 
				},				{
					name: '王先生',
					tel: '(182****0619)',
					title: '联系约好时间叫我去她家操她，皮肤还是很紧致' 
				},				{
					name: '李先生',
					tel: '(182****0621)',
					title: '床上满分 可一字马 性格超好 氵超多' 
				},				{
					name: '步先生',
					tel: '(139****0622)',
					title: '颜值很高，服务很专业，还会热舞...' 
				},				{
					name: '步先生',
					tel: '(135****0623)',
					title: '颜值很高，服务很专业，还会热舞...' 
				},				{
					name: '郑先生',
					tel: '(161****0624)',
					title: '颜值很高，服务很专业，还会热舞...' 
				},				{
					name: '郑先生',
					tel: '(155****0625)',
					title: '床上满分 可一字马 性格超好 氵超多' 
				},				{
					name: '郑先生',
					tel: '(198****0626)',
					title: '床上满分 可一字马 性格超好 氵超多' 
				},				{
					name: '陈先生',
					tel: '(186****0628)',
					title: '颜值很高，服务很专业，还会热舞...' 
				},				{
					name: '吕先生',
					tel: '(187****0629)',
					title: '床上满分 可一字马 性格超好 氵超多' 
				},				{
					name: '楚先生',
					tel: '(166****0630)',
					title: '颜值很高，服务很专业，还会热舞...' 
				},				{
					name: '王先生',
					tel: '(183****0631)',
					title: '床上满分 可一字马 性格超好 氵超多' 
				},				{
					name: '卫先生',
					tel: '(132****0632)',
					title: '床上满分 可一字马 性格超好 氵超多' 
				},				{
					name: '楚先生',
					tel: '(171****0634)',
					title: '朋友介绍的，妞可以年轻漂亮，服务够莞式。' 
				},				{
					name: '王先生',
					tel: '(183****0635)',
					title: '床上满分 可一字马 性格超好 氵超多' 
				},				{
					name: '赵先生',
					tel: '(158****0636)',
					title: '颜值很高，服务很专业，还会热舞...' 
				},				{
					name: '步先生',
					tel: '(176****0637)',
					title: '颜值很高，服务很专业，还会热舞...' 
				},				{
					name: '吴先生',
					tel: '(191****0638)',
					title: '颜值很高，服务很专业，还会热舞...' 
				},				{
					name: '冯先生',
					tel: '(177****0639)',
					title: '联系约好时间叫我去她家操她，皮肤还是很紧致' 
				},				{
					name: '吴先生',
					tel: '(177****0641)',
					title: '联系约好时间叫我去她家操她，皮肤还是很紧致' 
				},				{
					name: '孙先生',
					tel: '(196****0642)',
					title: '床上满分 可一字马 性格超好 氵超多' 
				}
			]
		};
	},
	methods: {
		addgo(data) {
			if (!localStorage.getItem('token')) {
				this.$router.push({ path: '/Login' });
			} else {
				this.$router.push({
					path: '/list?id=' + data.id + '&name=' + data.name
				});
			}
		},
		getAddress() {
			this.$http({
				method: 'get',
				url: 'indexlist'
			}).then(res => {
				this.xuanfeilist = res.data;
			});
		},
		xfts(id) {
			this.$router.push({ path: '/Detail?id=' + id });
		}
	},
	/*beforeRouteUpdate (to, from, next) {
		console.log(to);
        if (to.name != 'home' && to.name != 'login' && !localStorage.getItem("token")) {
			next("login");
			return;
        }
		next();
	},*/
	created() {
		this.getAddress();
	}
};
</script>

<style lang="less" scoped>
.page {
	position: absolute !important;
	top: 0;
	left: 0;
	right: 0;
	background-color: #16151b;
	/*height: 100%;*/
}
.nav-bar {
	// background: linear-gradient(90deg, #7e5678, #e6c3a1);
	height: 100px;
	background-color: #16151b;
}
.van-nav-bar {
	line-height: 50px;
}
::v-deep .van-nav-bar__title {
	max-width: 60%;
	margin: 0 auto;
	font-size: 35px;
	color: #f3c7a2;
	font-weight: bold;
}
::v-deep .van-nav-bar__content {
	height: 100px;
}
.van-sidebar {
	width: 180px;
}
.my-swipe {
	border-radius: 10px;
	height: 310px;
}
.my-swipe .van-swipe-item {
	color: #fff;
	font-size: 20px;
	// line-height: 510px;
	text-align: center;
	background-color: #39a9ed;
}
.convention-hall {
	// display: flex;
	// flex-direction: column;
	// bottom: 20px;
	// background: #f2f2f5;
	// height: 100%;
}
.convention-item {
	width: 95%;
	margin: 0 auto;
	margin-bottom: 3.5625rem;
}
.banner_img {
	object-fit: cover;
	display: block;
	width: 100%;
	height: 100%;
}
::v-deep .van-tab {
	font-size: 30px;
	line-height: 100px;
	font-weight: bold;
	color: #fff;
}
::v-deep .van-tabs__line {
	background-color: #f3c7a2;
}
::v-deep .van-tabs--line .van-tabs__wrap {
	height: 100px;
}
::v-deep .van-tabs__wrap--scrollable .van-tab {
	padding: 0 23px;
}
.describe {
	width: 100%;
	min-height: 9, 875rem;
	border-radius: 0.625rem;
	border: 1px solid #766350;
	margin: 0.625rem 0;
	background: linear-gradient(120deg, #212121, #313131);
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 0.5rem;
	position: relative;
	overflow: hidden;
}
.describe > ul {
	height: 5.1875rem;
	display: flex;
	align-items: flex-start;
}
.describe > ul > li:first-child {
	width: 7.75rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	border-right: 0.1875rem solid #e6b98a;
}
.describe > ul li {
	height: 100%;
}
.describe > div {
	height: 3.875rem;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	color: #e6b98a;
	line-height: 1.5rem;
	font-size: 0.75rem;
}
.describe > ul > li:first-child > div:first-child {
	font-size: 1rem;
	font-weight: 700;
}
.describe > ul > li:first-child div {
	color: #f3c7a2;
	height: 25%;
	display: flex;
	align-items: center;
	justify-content: center;
}
.describe > ul > li:first-child > div:nth-child(2) {
	font-size: 0.8125rem;
}
.describe > ul > li:first-child div {
	color: #f3c7a2;
	height: 25%;
	display: flex;
	align-items: center;
	justify-content: center;
}
.describe > ul > li:nth-child(2) {
	padding: 0 0 0 0.625rem;
	width: calc(100% - 7.6rem);
	font-size: 0.625rem;
	line-height: 1.5625rem;
	color: #fff;
}
.describe > ul li {
	height: 100%;
}
.card {
	background-color: #8a637d;
	padding: 0.625rem;
	width: 95%;
	color: white;
	font-size: 0.8125rem;
	margin: 0.625rem auto;
	border-radius: 0.375rem;
}
::v-deep .van-row--flex {
	height: 80px;
	line-height: 80px;
}
::v-deep .van-tabs__nav {
	background-color: #16151b;
}
.rig-box {
	width: 95%;
	margin: 0.625rem auto;
}
.rig-title {
	color: #0bdab0;
	font-size: 1.125rem;
}
.rig-content {
	font-size: 20px;
	// margin-top: 10px;
	color: #fff;
}
.address {
	width: 90%;
	margin: 0 auto;
}
.xuanfei {
	width: 100%;
	height: 9.75rem;
	border-radius: 0.625rem;
	border: 0.0625rem solid #766350;
	margin: 0.625rem 0 0.625rem 0;
	background: linear-gradient(120deg, #212121, #313131);
	display: flex;
	justify-content: space-between;
	padding: 0.625rem;
	position: relative;
	overflow: hidden;
}
.xuanfei .left-div {
	width: 12.9375rem;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
}
.xuanfei .right-div {
	width: 7.75rem;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}
.xuanfei .sign-i {
	position: absolute;
	right: -40px;
	top: 10px;
	background: #ff6056;
	font-size: 15px;
	padding: 10px 45px;
	font-style: normal;
	transform: rotate(45deg);
	color: #fff;
}
.xuanfei .left-div > div:first-child {
	background: linear-gradient(90deg, #efcba7, #dcab76);
	color: #8d5825;
	height: 1.25rem;
	padding: 0 1.25rem;
	border-radius: 0.625rem;
	line-height: 1.25rem;
	text-align: center;
	font-size: 1rem;
}
.xuanfei .left-div > div:nth-child(2) {
	margin: 0.625rem 0;
	padding-left: 0.625rem;
}
.xuanfei .left-div > div:nth-child(2) ul li {
	height: 1.125rem;
	line-height: 1.125rem;
	text-align: left;
	font-size: 0.875rem;
	color: #fff;
}
.xuanfei .left-div > div:nth-child(3) ul {
	display: flex;
}
.xuanfei .left-div > div:nth-child(3) ul > li:first-child {
	margin-right: 0.625rem;
}
.xuanfei .left-div > div:nth-child(3) ul li {
	display: flex;
	align-items: center;
	justify-content: flex-start;
}
.xuanfei .left-div > div:nth-child(3) ul li img {
	height: 0.8125rem;
	margin-right: 0.125rem;
}
.xuanfei .left-div > div:nth-child(3) ul li span {
	font-size: 0.8125rem;
	color: #696969;
}
.xuanfei .right-div img {
	width: 6.5rem;
	height: 6.5rem;
	-o-object-fit: cover;
	object-fit: cover;
	border-radius: 50%;
}
.scroll-cmp {
	width: 100%;
	height: 20.9375rem;
	border-radius: 0.625rem;
	border: 0.0625rem solid #766350;
	margin: 0.625rem 0 6.25rem 0;
	background: linear-gradient(120deg, #212121, #313131);
	display: flex;
	align-items: center;
	justify-content: flex-start;
	padding: 0.625rem;
	position: relative;
	overflow: hidden;
}
.scroll-cmp .van-swipe-item {
	height: 1.125rem;
}
.scroll-cmp .van-swipe-item div:first-child span {
	color: #e6b98a;
	font-size: 0.9375rem;
}
.scroll-cmp .van-swipe-item div:nth-child(2) span {
	color: #fff;
	font-size: 0.7125rem;
}
</style>
